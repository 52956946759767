<template>
  <div>
    <b-card>
      <div class="pr-5 pl-5">
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active">
              <div class="mt-2 pt-75">
                <div class="media mb-2">
                  <div class="media-body">
                    <b-row>
                      <b-col md="auto">
                        <b-media-body>
                          <!-- +++++++++++ -->
                          <b-avatar
                            size="80"
                            :src="imageUrl || user.picture"
                            :icon="'image'"
                          >
                          </b-avatar>
                          <!-- +++++++++++ -->
                        </b-media-body>
                      </b-col>
                      <b-col>
                        <h4 class="">
                          {{ user.firstname }} {{ user.lastname }}
                        </h4>
                        <span> {{ user.email }} </span><br />
                        <small> {{ roleName }}</small>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <b-card
                  bg-variant=""
                  header="Mes Informations"
                  class="text-center"
                >
                  <b-card-text class="text-left">
                    <validation-observer ref="updateCollaborator">
                      <b-overlay :show="loading" rounded="sm">
                        <form class="">
                          <div class="row">
                            <div class="col-md-6 col-12">
                              <div role="group" class="form-group">
                                <label for="name">Nom</label>
                                <div>
                                  <input
                                    id="name"
                                    type="text"
                                    class="form-control"
                                    v-model="user.firstname"
                                  /><!----><!----><!---->
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-12">
                              <div role="group" class="form-group">
                                <label for="lastname" class="d-block"
                                  >Prénom(s)</label
                                >
                                <div>
                                  <input
                                    id="lastname"
                                    type="text"
                                    class="form-control"
                                    v-model="user.lastname"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-12">
                              <div role="group" class="form-group">
                                <label for="adress" class="d-block"
                                  >Adresse</label
                                >
                                <div>
                                  <input
                                    id="adress"
                                    type="text"
                                    class="form-control"
                                    v-model="user.address"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-12">
                              <div role="group" class="form-group">
                                <label for="email" class="d-block"
                                  >Situation matrimoniale</label
                                >
                                <div>
                                  <b-form-select v-model="user.marital_status">
                                    <option value="Célibataire avec enfant">
                                      Célibataire avec enfant
                                    </option>
                                    <option value="Célibataire sans enfant">
                                      Célibataire sans enfant
                                    </option>

                                    <option value="Marié">Marié(e)</option>
                                    <option value="Divorcé">Divorcé(e)</option>
                                    <option value="Veuf/Veuve">
                                      Veuf/Veuve
                                    </option>
                                  </b-form-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-12">
                              <div role="group" class="form-group">
                                <label for="phone" class="d-block"
                                  >Téléphone</label
                                >
                                <div>
                                  <input
                                    id="phone"
                                    type="number"
                                    class="form-control"
                                    v-model="user.telephone"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-12 text-left">
                              <div role="group" class="form-group">
                                <label for="email" class="d-block">Rôle</label>
                                <div>
                                  <b-form-select v-model="user.role_id">
                                    <b-form-select-option value="1"
                                      >Administrateur</b-form-select-option
                                    >
                                    <b-form-select-option value="2"
                                      >Utilisateur
                                      standard</b-form-select-option
                                    >
                                  </b-form-select>
                                </div>
                              </div>
                            </div>

                            <!-- work -->
                            <b-col md="6">
                              <b-form-group>
                                <label>Service</label>
                                <b-form-select v-model="serviceId">
                                  <b-form-select-option
                                    :value="service.id"
                                    v-for="service in services"
                                    :key="service.id"
                                  >
                                    {{ service.name }}
                                  </b-form-select-option></b-form-select
                                >
                              </b-form-group>
                            </b-col>

                            <!-- function -->
                            <b-col md="6">
                              <b-form-group>
                                <label>Fonction</label>
                                <b-form-select v-model="user.fonction_id">
                                  <b-form-select-option :value="null"
                                    >Choisir une fonction</b-form-select-option
                                  >
                                  <b-form-select-option
                                    :value="fonction.id"
                                    v-for="fonction in fonctions"
                                    :key="fonction.length"
                                  >
                                    {{ fonction.name }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>
                            
                            <!-- contract -->
                            <b-col md="12">
                              <b-form-group>
                                <label>Type de contrat</label>
                                <b-form-select v-model="user.contract_id">
                                  <b-form-select-option :value="null"
                                    >Choisir un contrat</b-form-select-option
                                  >
                                  <b-form-select-option
                                    :value="contract.id"
                                    v-for="contract in contracts"
                                    :key="contract.id"
                                  >
                                    {{ contract.name }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>

                            <b-col md="6">
                              <b-form-group>
                                <validation-provider
                                  #default="{ errors }"
                                  name="checkbox"
                                >
                                  <b-checkbox
                                    :value="1"
                                    v-model="user.is_supervisor"
                                    >Ce collaborateur est un
                                    superviseur</b-checkbox
                                  >
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                              <b-col md="6">
                              <b-form-group>
                                <validation-provider
                                  #default="{ errors }"
                                  name="checkbox"
                                >
                                  <b-checkbox
                                    :value="1"
                                    v-model="user.is_hire"
                                    >Ce collaborateur est toujours dans l'entreprise</b-checkbox
                                  >
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </div>
                          <button
                            type="button"
                            class="
                              btn
                              mb-1 mb-sm-0
                              mr-0 mr-sm-1
                              btn-primary btn-block
                            "
                            @click="updateColl"
                          >
                            Mettre à jour
                          </button>
                          <b-link
                            :to="'/admin/member/profile/' + user.id"
                            class="btn btn-outline-secondary btn-block"
                          >
                            Annuler
                          </b-link>
                        </form>
                      </b-overlay>
                    </validation-observer>
                  </b-card-text>
                </b-card>
              </div>
            </div>

            <!---->
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      firstname: "",
      loading: false,
      errorMessage: "",
      error: "",
      errors: "",
      errorModal: false,
      imageUrl: null,
      serviceId: null,
    };
  },
  components: {
    ValidationObserver,
  },

  computed: {
    ...mapState("auth", ["user", "users"]),
    ...mapState("enterprise", ["fonctions", "services", 'contracts']),

    roleName() {
      return this.user.role?.name;
    },
    ...mapState("auth", ["users", "user"]),
  },

  mounted() {
    this.showUser(this.$route.params.id).then((r) => {
      this.serviceId = this.user.fonction.service_id;
    });
    this.fetchServices();
    this.getContracts();
    this.fetchFonctions(this.user.service_id);
  },
  watch: {
    serviceId() {
      this.fetchFonctions(this.serviceId);
    },
  },

  methods: {
    onFileUpload(event) {
      this.uploaded = true;
      var file = event.target.files[0];
      if (file) this.imageUrl = URL.createObjectURL(file);

      this.user.picture = file;
      this.user.picture_only = 1;
      this.updateColl();
    },
    openFilePicker() {
      document.getElementById("picture").click();
    },
    async updateColl() {
      const valid = await this.$refs.updateCollaborator.validate();
      if (!valid) return;
      this.loading = true;
      if (this.user.is_supervisor == false) this.user.is_supervisor = 0;
      this.updateEnterpriseUser(this.$formData(this.user))
        .then((r) => {
          this.loading = false;
          this.$router.push({
            name: "admin-member-profile-id",
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorModal = true;
          this.errorMessage = error.message;
          this.popover_disabled = false;
          this.errors = error;
        });
    },
    ...mapActions("auth", ["showUser", "updateEnterpriseUser"]),
    ...mapActions("enterprise", [
      "fetchServices",
      "fetchFonctions",
      "enterpriseUser",
      'getContracts'
    ]),
  },
};
</script>


<style>
@media only screen and (max-width: 600px) {
  .bouton {
    margin-top: 2%;
  }
}
</style>
